import Vue from 'vue'
import { formatDistanceToNow, fromUnixTime } from 'date-fns'
import arrayToSentence from 'array-to-sentence'
import helpers from '@/helpers/utils'

Vue.filter('first4Chars', (str) => str.substring(0, 4))
Vue.filter('last4Chars', (str) => str.substring(str.length - 4))
Vue.filter('ago', (date) => formatDistanceToNow(new Date(fromUnixTime(date.seconds)), { addSuffix: true }))
Vue.filter('safeName', (string) => helpers.shortName(string))
Vue.filter('arrayToSentence', (array) => arrayToSentence(array))
