import Vue from 'vue'

import App from './App.vue'

import router from './router'
import store from './store'
import firebase from './helpers/firebaseInit.js'
import './filters'
import '../node_modules/firebaseui/dist/firebaseui.css'
import '@/assets/styles/main.css'
import '@/plugins/echarts'
import helpers from '@/helpers/utils'
import Vuelidate from 'vuelidate'
import VueMeta from 'vue-meta'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

Sentry.init({
	dsn: 'https://091ce42f216b4b548cb01e69e245aa7a@o395805.ingest.sentry.io/5248281',
	integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })]
})

Vue.use(Vuelidate)
Vue.use(VueMeta, {
	// optional pluginOptions
	refreshOnceOnNavigation: true
})
Vue.use(helpers)

Vue.component('Confirmation', () => import('@/components/shared/confirmationModal'))

Vue.prototype.$firebase = firebase

import './registerServiceWorker'
Vue.config.productionTip = false

let app = ''

Vue.mixin({
	methods: {
		shortName(name) {
			return name ? helpers.shortName(name) : ''
		}
	}
})

Vue.filter('capitalize', function(value) {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.prototype.$analytics = firebase.analytics
Vue.prototype.$sendInBlue = window.sendinblue

firebase.auth.onAuthStateChanged(() => {
	store
		.dispatch('auth/checkUserStatus')
		.then((account) => {
			if (account) {
				let subscription = store.state.accounts.current.subscription ? store.state.accounts.current.subscription.plan : ''

				firebase.analytics.setUserId(firebase.auth.currentUser.uid)
				firebase.analytics.logEvent('login', { method: 'magic_link' })

				let email = firebase.auth.currentUser.email
				var properties = {
					id: firebase.auth.currentUser.uid,
					account: store.state.accounts.current ? store.state.accounts.current.id : '',
					accountPlan: subscription,
					stripe_id: store.state.auth.user.stripe_id
				}

				window.sendinblue.identify(email, properties)
			}
		})
		.catch((e) => {
			console.log(e)
		})

	if (!app) {
		app = new Vue({
			router,
			store,
			render: (h) => h(App)
		}).$mount('#app')
	}
})
