import firebase from 'firebase/app'
require('firebase/firestore')
require('firebase/auth')
require('firebase/performance')
require('firebase/analytics')
require('firebase/functions')
require('firebase/storage')
import firebaseConfig from './firebaseConfig'

const firebaseApp = firebase.initializeApp(firebaseConfig[process.env.NODE_ENV])
const firestore = firebaseApp.firestore()
const auth = firebaseApp.auth()
var perf = firebase.performance()
var functions = firebase.functions()
var storage = firebase.storage()
var analytics = firebase.analytics()

// firebase collections
const modelCollection = firestore.collection('models')
const entityCollection = firestore.collection('entities')
const fieldCollection = firestore.collection('fields')
const ruleCollection = firestore.collection('rules')
const creatorCollection = firestore.collection('creators')
const channelCollection = firestore.collection('channels')
const userCollection = firestore.collection('users')
const accountCollection = firestore.collection('accounts')
const configurationCollection = firestore.collection('configurations')
const membershipCollection = firestore.collection('memberships')
const connectionCollection = firestore.collection('connections')

export default {
	//currentUser,
	firestore,
	modelCollection,
	entityCollection,
	fieldCollection,
	ruleCollection,
	creatorCollection,
	channelCollection,
	userCollection,
	accountCollection,
	configurationCollection,
	membershipCollection,
	connectionCollection,
	auth,
	perf,
	analytics,
	functions,
	storage
}
