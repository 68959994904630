<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import helpers from '@/helpers/utils'
import firebase from '@/helpers/firebaseInit.js'
import { version } from '../package.json'

export default {
	name: 'App',
	computed: {
		...mapState('auth', {
			userId: (state) => state.user.id
		})
	},
	metaInfo: {
		changed(metaInfo) {
			firebase.analytics.setCurrentScreen(metaInfo.title)
			firebase.analytics.logEvent('page_view')
			firebase.analytics.logEvent('screen_view', {
				app_name: helpers.isPWA() ? 'pwa' : 'web',
				screen_name: metaInfo.title,
				app_version: version
			})
		}
	},
	mounted() {
		firebase.auth.onAuthStateChanged((user) => {
			if (user) {
				this.$analytics.setUserId(user.uid)
				this.$analytics.setUserProperties({
					account_type: this.$store.state.accounts.current.subscription ? this.$store.state.accounts.current.subscription.plan : '', // can help you to define audiences
					account_domain: user.email.split('@')[1],
					account_name: this.$store.state.accounts.current ? this.$store.state.accounts.current.id : ''
				})
			}
		})
	}
}
</script>
