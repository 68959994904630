import { EntityService as Service } from './common/api.service'
import { sharedActions, sharedState, sharedMutations, sharedGetters } from './shared.module'

const initialState = {
	...sharedState
}

let entityActions = sharedActions(Service)

export const incrementField = (commit, data) => {
	commit(INCREMENT_FIELD, {
		fieldCount: data.sourceSingleName + 'Count',
		field: data.sourceCollectionName,
		item: { name: data.name, id: data.id }
	})
}
export const decrementField = (commit, data) => {
	commit(DECREMENT_FIELD, {
		fieldCount: data.sourceSingleName + 'Count',
		field: data.sourceCollectionName,
		id: data.id
	})
}

import { GET_ALL_BY_MODEL, SELECT, ADD_PROPERTY_AND_COUNT, REMOVE_ROPERTY_AND_COUNT, ENTITY, FIELD } from './actions.type'

import { RESET_STATE, SET_CURRENT_BY_ID, INCREMENT_FIELD, DECREMENT_FIELD } from './mutations.type'

export const state = { ...initialState }

const getters = {
	...sharedGetters,
	entities: (state) => state.all,
	entity: (state) => state.current,
	otherEntities: (state) =>
		state.all.filter((e) => {
			return e.id !== state.current.id
		}),
	incompleteConnections: (state, getters) => {
		return getters.connections.filter((fc) => fc.assignedInfo.some((f) => !f.hasTargetAndSource))
	},
	connections: (state, getters, rootState, rootGetters) => {
		let fieldInfo = []
		for (const f of state.all) {
			let source = rootGetters['connections/source'](f.id)
			let target = rootGetters['connections/target'](f.id)
			let fInfo = { name: f.name }
			fInfo.assignedInfo = []
			if (f.assignedTo) {
				for (const assigned of Object.values(f.assignedTo)) {
					let sourceConnections = source.filter((s) => s.modelId === assigned.id)
					let targetConnections = target.filter((s) => s.modelId === assigned.id)
					let isSource = sourceConnections.length > 0
					let isTarget = targetConnections.length > 0
					let hasTargetAndSource = isSource && isTarget

					fInfo.assignedInfo.push({
						name: assigned.name,
						id: assigned.id,
						sourceConnections,
						targetConnections,
						isSource,
						isTarget,
						hasTargetAndSource
					})
				}
				fieldInfo.push(fInfo)
			}
		}
		return fieldInfo
	}
}
const mutations = {
	...sharedMutations,
	[INCREMENT_FIELD](state, { field, id, fieldCount }) {
		let itemCount = state.current[fieldCount] ? state.current[fieldCount] : 0
		itemCount++
		state.current[fieldCount] = itemCount

		let newItem = { [id]: true }
		state.current[field] = { ...state.current[field], ...newItem }
	},
	[DECREMENT_FIELD](state, { field, id, fieldCount }) {
		let itemCount = state.current[fieldCount] ? state.current[fieldCount] : 1
		itemCount--
		state.current[fieldCount] = itemCount

		let f = state.current[field] ? state.current[field] : {}

		delete f[id]
	}
}
const actions = {
	...entityActions,
	async [SELECT]({ commit }, id) {
		commit(SET_CURRENT_BY_ID, {
			id
		})
	},
	async [GET_ALL_BY_MODEL]({ commit }, modelId) {
		commit(RESET_STATE)

		return Service.getAllByModel(modelId)
			.then((snapshot) => {
				if (snapshot.empty) {
					return
				}
				snapshot.forEach((doc) => {
					let data = doc.data()
					let entity = {
						id: doc.id,
						...data
					}
					commit(ENTITY.SET, {
						entity
					})
				})
			})
			.catch((err) => {
				console.log(err)
			})
	},
	async [FIELD.ADD]({ commit }, data) {
		let e = await Service.addField(data).then(() => {
			incrementField(commit, data)
		})
		//
		// await this.dispatch(`configurations/${FIELD.ADD}`, data, {
		// 	root: true
		// })

		return e
	},
	async [ADD_PROPERTY_AND_COUNT]({ commit }, data) {
		data.parentId = state.current.id
		return Service.addFieldAndCount(data).then(() => {
			commit(INCREMENT_FIELD, {
				fieldCount: data.fieldCountName,
				field: data.fieldName,
				id: data.itemId
			})
		})
	},
	async [REMOVE_ROPERTY_AND_COUNT]({ commit }, data) {
		data.parentId = state.current.id
		return Service.removeFieldAndCount(data).then(() => {
			commit(DECREMENT_FIELD, {
				fieldCount: data.fieldCountName,
				field: data.fieldName,
				id: data.itemId
			})
		})
	}
}
export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
