import { AccountService as Service } from './common/api.service'
import { ACCOUNT } from './actions.type'
import { SET_CURRENT, UNSET_CURRENT } from './mutations.type'
import firebase from '@/helpers/firebaseInit.js'

let accountActions = sharedActions(Service)

import { sharedActions, sharedState, sharedMutations, sharedGetters } from './shared.module'

const initialState = {
	...sharedState
}

export const state = { ...initialState }

const getters = {
	...sharedGetters,
	account: (state) => state.current,
	accounts: (state) => state.all,
	isOwner: (state) => state.current.owner === firebase.auth.currentUser.uid
}

const mutations = {
	...sharedMutations
}

const actions = {
	...accountActions,
	async [ACCOUNT.ADD]({ commit }, account) {
		return Service.upsert(account)
			.then((item) => {
				if (!item) {
					commit(UNSET_CURRENT)
					return false
				}
				let data = item.data()
				let itemData = {
					id: item.id,
					...data
				}
				commit(SET_CURRENT, {
					item: itemData
				})

				return itemData
			})
			.catch((err) => {
				console.log(err)
			})
	},

	async [ACCOUNT.EXISTING]({ commit }, accountInfo) {
		return Service.addExisting(accountInfo).then((item) => {
			if (!item) {
				commit(UNSET_CURRENT)
				return false
			}
			let data = item.data()
			let itemData = {
				id: item.id,
				...data
			}
			commit(SET_CURRENT, {
				item: itemData
			})

			return true
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
