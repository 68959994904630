import { ChannelService as Service } from './common/api.service'
import { SELECT } from './actions.type'
import { sharedActions, sharedState, sharedMutations, sharedGetters } from './shared.module'

let channelActions = sharedActions(Service)

import { SET_CURRENT_BY_ID } from './mutations.type'

const initialState = {
	...sharedState
}

export const state = { ...initialState }

const getters = {
	...sharedGetters,
	channel: (state) => state.current,
	channels: (state) => state.all,
	incompleteConnections: (state, getters) => {
		return getters.connections.filter((fc) => fc.assignedInfo.some((f) => !f.isTarget))
	},
	connections: (state, getters, rootState, rootGetters) => {
		let fieldInfo = []
		for (const f of state.all) {
			let target = rootGetters['connections/target'](f.id)
			let fInfo = { name: f.name }
			fInfo.assignedInfo = []
			if (f.assignedTo) {
				for (const assigned of Object.values(f.assignedTo)) {
					let targetConnections = target.filter((s) => s.modelId === assigned.id)
					let isTarget = targetConnections.length > 0
					fInfo.assignedInfo.push({
						name: assigned.name,
						id: assigned.id,
						targetConnections,
						isTarget
					})
				}
				fieldInfo.push(fInfo)
			}
		}
		return fieldInfo
	}
}

const mutations = {
	...sharedMutations
}

const actions = {
	...channelActions,
	async [SELECT]({ commit }, id) {
		commit(SET_CURRENT_BY_ID, {
			id
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
