import { ModelService as Service } from './common/api.service'

import { sharedActions, sharedState, sharedMutations, sharedGetters, incrementField, decrementField } from './shared.module'

const modelActions = sharedActions(Service)

import { ADD_PROPERTY_AND_COUNT, FIELD, ENTITY, CHANNEL, CREATOR, RULE } from './actions.type'

import { INCREMENT_FIELD } from './mutations.type'

const initialState = {
	...sharedState
}

export const state = { ...initialState }

const getters = {
	...sharedGetters,
	model: (state) => state.current,
	models: (state) => state.all
}

const mutations = {
	...sharedMutations
}

// ACTIONS
const actions = {
	...modelActions,
	async [ADD_PROPERTY_AND_COUNT]({ commit }, data) {
		data.parentId = state.current.id
		return Service.addFieldAndCount(data).then(() => {
			commit(INCREMENT_FIELD, {
				fieldCount: data.fieldCountName,
				field: data.fieldName,
				id: data.itemId
			})
		})
	},
	async [ENTITY.ADD]({ commit }, data) {
		return await Service.addEntity(data).then(() => {
			incrementField(commit, data)
		})
	},
	async [ENTITY.REMOVE]({ commit }, data) {
		return Service.removeEntity(data).then(() => {
			decrementField(commit, data)
		})
	},
	async [FIELD.ADD]({ commit }, data) {
		return await Service.addField(data).then(() => {
			incrementField(commit, data)
		})
	},
	async [RULE.ADD]({ commit }, data) {
		return Service.addRule(data).then(() => {
			incrementField(commit, data)
		})
	},
	async [CHANNEL.ADD]({ commit }, data) {
		return Service.addChannel(data).then(() => {
			incrementField(commit, data)
		})
	},
	async [CREATOR.ADD]({ commit }, data) {
		return Service.addCreator(data).then(() => {
			incrementField(commit, data)
		})
	},
	async [CREATOR.REMOVE]({ commit }, data) {
		return Service.removeCreator(data).then(() => {
			decrementField(commit, data)
		})
	},
	async [CHANNEL.REMOVE]({ commit }, data) {
		return Service.removeChannel(data).then(() => {
			decrementField(commit, data)
		})
	},
	async [RULE.REMOVE]({ commit }, data) {
		return Service.removeRule(data).then(() => {
			decrementField(commit, data)
		})
	},
	async [FIELD.REMOVE]({ commit }, data) {
		return Service.removeField(data).then(() => {
			decrementField(commit, data)
		})
	}
}
export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
}
