import router from '@/router'
import firebase from '@/helpers/firebaseInit.js'
// import { app } from 'firebase'

const state = {
	user: {}
}

const actions = {
	logout({ commit }) {
		return new Promise((resolve, reject) => {
			firebase.auth
				.signOut()
				.then(() => {
					commit('LOGOUT')
					resolve()
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	async refreshUser({ commit }, id) {
		const userRecordRef = firebase.userCollection.doc(id)
		let userInfo = {}

		return userRecordRef.get().then((doc) => {
			if (doc.exists) {
				userInfo = { ...state.user, ...doc.data() }
			}
			commit('SET_USER', { user: userInfo })
		})
	},
	checkUserStatus({ commit }) {
		return new Promise((resolve, reject) => {
			firebase.auth.onAuthStateChanged(async (user) => {
				if (user) {
					let appUser = {
						id: user.uid,
						// User is signed in.
						displayName: user.displayName,
						email: user.email,
						emailVerified: user.emailVerified,
						photoURL: user.photoURL,
						uid: user.uid,
						phoneNumber: user.phoneNumber,
						providerData: user.providerData
					}

					const userRecordRef = firebase.userCollection.doc(user.uid)
					await userRecordRef
						.get()
						.then(async (doc) => {
							if (doc.exists) {
								if (doc.data().onboardingStatus === 'new_user') {
									router.push('/onboarding')
								}

								appUser = { ...appUser, ...doc.data() }
							}
						})
						.then(() => {
							commit('SET_USER', { user: appUser })
						})
					resolve(user.uid)
				} else {
					reject('User is not logged in.')
				}
			})
		})
	}
}

const mutations = {
	LOGOUT(state) {
		state.user = {}
	},
	SET_USER(state, { user }) {
		state.user = user
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations
}
