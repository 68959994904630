import { FieldService as Service } from './common/api.service'

import { ADD_PROPERTY_AND_COUNT, REMOVE_ROPERTY_AND_COUNT } from './actions.type'
import { sharedActions, sharedState, sharedMutations, sharedGetters } from './shared.module'

let fieldActions = sharedActions(Service)

import { INCREMENT_FIELD, DECREMENT_FIELD } from './mutations.type'

const initialState = {
	...sharedState
}
export const state = {
	...initialState
}

const getters = {
	...sharedGetters,
	fields: (state) => state.all,
	field: (state) => state.current,
	gdprFields: (state) => state.all.filter((f) => f.gdprStatus && f.gdprStatus !== 'no'),
	fieldGdprPurposeCount: (state) => {
		let total = state.all.filter((f) => f.gdprStatus && f.gdprStatus !== 'no')
		return {
			total: total.length,
			count: total.filter((f) => !f.gdprPurpose).length
		}
	},
	incompleteConnections: (state, getters) => {
		return getters.connections.filter((fc) => fc.assignedInfo.some((f) => !f.hasTargetAndSource))
	},
	connections: (state, getters, rootState, rootGetters) => {
		let fieldInfo = []
		for (const f of state.all) {
			let source = rootGetters['connections/source'](f.id)
			let target = rootGetters['connections/target'](f.id)
			let fInfo = { name: f.name }
			fInfo.assignedInfo = []
			if (f.assignedTo) {
				for (const assigned of Object.values(f.assignedTo)) {
					let sourceConnections = source.filter((s) => s.modelId === assigned.id)
					let targetConnections = target.filter((s) => s.modelId === assigned.id)
					let isSource = sourceConnections.length > 0
					let isTarget = targetConnections.length > 0
					let hasTargetAndSource = isSource && isTarget

					fInfo.assignedInfo.push({
						name: assigned.name,
						id: assigned.id,
						sourceConnections,
						targetConnections,
						isSource,
						isTarget,
						hasTargetAndSource
					})
				}
				fieldInfo.push(fInfo)
			}
		}
		return fieldInfo
	}
}

const mutations = {
	...sharedMutations,
	[INCREMENT_FIELD](state, { field, id, fieldCount }) {
		let itemCount = state.current[fieldCount] ? state.current[fieldCount] : 0
		itemCount++
		state.current[fieldCount] = itemCount

		let newItem = {
			[id]: true
		}
		state.current[field] = {
			...state.current[field],
			...newItem
		}
	},
	[DECREMENT_FIELD](state, { field, id, fieldCount }) {
		let itemCount = state.current[fieldCount] ? state.current[fieldCount] : 1
		itemCount--
		state.current[fieldCount] = itemCount

		let f = state.current[field] ? state.current[field] : {}

		delete f[id]
	}
}

const actions = {
	...fieldActions,
	async [ADD_PROPERTY_AND_COUNT]({ commit }, data) {
		data.parentId = state.current.id

		return Service.addFieldAndCount(data).then(() => {
			commit(INCREMENT_FIELD, {
				fieldCount: data.fieldCountName,
				field: data.fieldName,
				id: data.itemId
			})
		})
	},
	async [REMOVE_ROPERTY_AND_COUNT]({ commit }, data) {
		data.parentId = state.current.id
		return Service.removeFieldAndCount(data).then(() => {
			commit(DECREMENT_FIELD, {
				fieldCount: data.fieldCountName,
				field: data.fieldName,
				id: data.itemId
			})
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
