const production = {
	apiKey: 'AIzaSyDI1KB_89nUqkEyKqBXN4QgN3PbTpd1vtw',
	authDomain: 'app.storism.io',
	databaseURL: 'https://storism-7f1ea.firebaseio.com',
	projectId: 'storism',
	storageBucket: 'storism.appspot.com',
	messagingSenderId: '578876851432',
	appId: '1:578876851432:web:538e3f40508d88b830755d',
	measurementId: 'G-B84FQM03CH'
}

const development = {
	// apiKey: 'AIzaSyB2KVHTZh7rkIuSM0Nd3uiqJiOKtWt4tP0',
	// authDomain: 'app.storism.io',
	// databaseURL: 'https://storism-dev.firebaseio.com',
	// projectId: 'storism-dev',
	// storageBucket: 'storism-dev.appspot.com',
	// messagingSenderId: '150753341130',
	// appId: '1:150753341130:web:0c4239de961e87f22fce33',
	// measurementId: 'G-HZ2SN6WYW7'
	apiKey: 'AIzaSyDI1KB_89nUqkEyKqBXN4QgN3PbTpd1vtw',
	authDomain: 'app.storism.io',
	databaseURL: 'https://storism-7f1ea.firebaseio.com',
	projectId: 'storism',
	storageBucket: 'storism.appspot.com',
	messagingSenderId: '578876851432',
	appId: '1:578876851432:web:538e3f40508d88b830755d',
	measurementId: 'G-B84FQM03CH'
}

export default {
	production,
	development
}
