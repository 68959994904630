const crypto = require('crypto')

const getRandomId = () => {
	return crypto.randomBytes(12).toString('hex')
}

const shortName = (string) => {
	return string
		.toString()
		.trim()
		.toLowerCase()
		.replace(/\s+/g, '-')
		.replace(/[^\w-]+/g, '')
		.replace(/--+/g, '-')
		.replace(/^-+/, '')
		.replace(/-+$/, '')
}

const isPWA = () => {
	return window && window.matchMedia('(display-mode: standalone)').matches
}

export default {
	getRandomId,
	shortName,
	isPWA
}
