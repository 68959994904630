import { CreatorService as Service } from './common/api.service'

import { GET_ALL_BY_MODEL, SELECT } from './actions.type'

import { RESET_STATE, CREATOR, SET_CURRENT_BY_ID } from './mutations.type'
import { sharedActions, sharedState, sharedMutations, sharedGetters } from './shared.module'

const initialState = {
	...sharedState
}

export const state = { ...initialState }

let creatorActions = sharedActions(Service)

const getters = {
	...sharedGetters,
	creator: (state) => state.current,
	creators: (state) => state.all,
	incompleteConnections: (state, getters) => {
		return getters.connections.filter((fc) => fc.assignedInfo.some((f) => !f.isSource))
	},
	connections: (state, getters, rootState, rootGetters) => {
		let fieldInfo = []
		for (const f of state.all) {
			let source = rootGetters['connections/source'](f.id)
			let fInfo = { name: f.name }
			fInfo.assignedInfo = []
			if (f.assignedTo) {
				for (const assigned of Object.values(f.assignedTo)) {
					let sourceConnections = source.filter((s) => s.modelId === assigned.id)
					let isSource = sourceConnections.length > 0
					fInfo.assignedInfo.push({
						name: assigned.name,
						id: assigned.id,
						sourceConnections,
						isSource
					})
				}
				fieldInfo.push(fInfo)
			}
		}
		return fieldInfo
	}
}

const mutations = {
	...sharedMutations
}

const actions = {
	...creatorActions,
	async [GET_ALL_BY_MODEL]({ commit }, modelId) {
		commit(RESET_STATE)

		return Service.getAllByModel(modelId)
			.then((snapshot) => {
				if (snapshot.empty) {
					return
				}
				snapshot.forEach((doc) => {
					let data = doc.data()
					let creator = {
						id: doc.id,
						...data
					}
					commit(CREATOR.SET, {
						creator
					})
				})
			})
			.catch((err) => {
				console.log(err)
			})
	},
	async [SELECT]({ commit }, modelId) {
		commit(SET_CURRENT_BY_ID, {
			modelId
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
