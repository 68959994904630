import { UserService as Service } from './common/api.service'

import { GET, ADD, DELETE, UPDATE, ADD_PROPERTY_AND_COUNT, REMOVE_ROPERTY_AND_COUNT } from './actions.type'

import { RESET_STATE, USER, INCREMENT_FIELD, DECREMENT_FIELD } from './mutations.type'

const initialState = {
	current: {}
}

export const state = { ...initialState }

const getters = {
	user: (state) => state.current
}

const mutations = {
	[USER.SET](state, { user }) {
		state.current = user
	},
	[USER.REMOVE](state) {
		state.current = {}
	},
	[USER.UPDATE](state, { user }) {
		state.current = user
	},
	[RESET_STATE](state) {
		state.current = {}
	},
	[INCREMENT_FIELD](state, { field, id, fieldCount }) {
		let itemCount = state.current[fieldCount] ? state.current[fieldCount] : 0
		itemCount++
		state.current[fieldCount] = itemCount

		let newItem = { [id]: true }
		state.current[field] = { ...state.current[field], ...newItem }
	},
	[DECREMENT_FIELD](state, { field, id, fieldCount }) {
		let itemCount = state.current[fieldCount] ? state.current[fieldCount] : 1
		itemCount--
		state.current[fieldCount] = itemCount

		let f = state.current[field] ? state.current[field] : {}

		delete f[id]
	}
}

const actions = {
	async [GET]({ commit }, userId) {
		return Service.get(userId).then((user) => {
			let data = user.data()
			let x = {
				id: user.id,
				...data
			}
			commit(USER.SET, {
				user: x
			})
		})
	},
	async [ADD]({ commit, rootState }, data) {
		data.userId = rootState.auth.user.id
		return Service.upsert(data).then((user) => {
			let d = {
				itemId: user.id,
				fieldName: 'users',
				fieldCountName: 'userCount'
			}
			this.dispatch(`users/${ADD_PROPERTY_AND_COUNT}`, d, {
				root: true
			})

			let x = {
				id: user.id,
				...data
			}
			commit(USER.SET, {
				user: x
			})
		})
	},
	async [UPDATE]({ commit }, user) {
		return Service.update(user).then((user) => {
			commit(USER.UPDATE, {
				user
			})
		})
	},
	async [DELETE]({ commit }, id) {
		return Service.delete(id)
			.then(() => {
				commit(USER.REMOVE, {
					id
				})
			})
			.then(() => {
				let d = {
					itemId: id,
					fieldName: 'users',
					fieldCountName: 'userCount'
				}
				this.dispatch(`models/${REMOVE_ROPERTY_AND_COUNT}`, d, {
					root: true
				})
			})
	},
	async [ADD_PROPERTY_AND_COUNT]({ commit }, data) {
		data.parentId = state.current.id
		return Service.addFieldAndCount(data).then(() => {
			commit(INCREMENT_FIELD, {
				fieldCount: data.fieldCountName,
				field: data.fieldName,
				id: data.itemId
			})
		})
	},
	async [REMOVE_ROPERTY_AND_COUNT]({ commit }, data) {
		data.parentId = state.current.id
		return Service.removeFieldAndCount(data).then(() => {
			commit(DECREMENT_FIELD, {
				fieldCount: data.fieldCountName,
				field: data.fieldName,
				id: data.itemId
			})
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
