import { RuleService as Service } from './common/api.service'
import { sharedActions, sharedState, sharedMutations } from './shared.module'
let ruleActions = sharedActions(Service)

import { GET_ALL_BY_MODEL } from './actions.type'

import { RESET_STATE, RULE } from './mutations.type'

const initialState = {
	...sharedState
}

export const state = { ...initialState }

const getters = {
	rules: (state) => state.all,
	entityRules: (state) => (id) => state.all.filter((rule) => rule.entityId == id),
	fieldRules: (state) => (id) => state.all.filter((rule) => rule.fieldId == id),
	rulesEntityOther: (state) => (id) => state.all.filter((rule) => rule.entityId != id),
	rulesFieldOther: (state) => (id) => state.all.filter((rule) => rule.field != id),
	rule: (state) => state.current
}

const mutations = {
	...sharedMutations
}

const actions = {
	...ruleActions,
	async [GET_ALL_BY_MODEL]({ commit }, modelId) {
		commit(RESET_STATE)

		return Service.getAllByModel(modelId)
			.then((snapshot) => {
				if (snapshot.empty) {
					return
				}
				snapshot.forEach((doc) => {
					let data = doc.data()
					let rule = {
						id: doc.id,
						...data
					}
					commit(RULE.SET, {
						rule
					})
				})
			})
			.catch((err) => {
				console.log(err)
			})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
