export const CHECK_AUTH = 'checkAuth'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const REGISTER = 'register'
export const UPDATE_USER = 'updateUser'
export const WATCH = 'watch'
export const UNWATCH = 'unwatch'
export const GET = 'get'
export const GET_ALL = 'getAll'
export const GET_ALL_BY_MODEL = 'getAllByModel'
export const ADD = 'add'
export const UPSERT = 'upsert'
export const UPSERT_BATCH = 'upsertBatch'
export const DELETE = 'delete'
export const UPDATE = 'update'
export const SELECT = 'select'
export const ADD_PROPERTY_AND_COUNT = 'addFieldAndCount'
export const REMOVE_ROPERTY_AND_COUNT = 'deleteFieldAndCount'
export const ASSIGNMENT = { ADD: 'assign', REMOVE: 'unassign' }
export const RULE = { ADD: 'addRule', REMOVE: 'removeRule' }
export const FIELD = { ADD: 'addField', REMOVE: 'removeField' }
export const ENTITY = { ADD: 'addEntity', REMOVE: 'removeEntity' }
export const MODEL = { ADD: 'addModel', REMOVE: 'removeModel' }
export const CHANNEL = { ADD: 'addChannel', REMOVE: 'removeChannel' }
export const CONNECTION = {
	ADD: 'addConnection',
	REMOVE: 'removeConnection',
	ADD_BATCH: 'addBatchConnections',
	REMOVE_BATCH: 'removeBatchConnection'
}
export const CREATOR = { ADD: 'addCreator', REMOVE: 'removeCreator' }
export const ACCOUNT = { ADD: 'addAccount', REMOVE: 'removeAccount', EXISTING: 'addExistingAccount' }
