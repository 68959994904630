import { CHANNEL, ENTITY, FIELD } from '@/store/actions.type'
import Vue from 'vue'
import Router from 'vue-router'
import Auth from './components/Auth.vue'
import AuthSuccess from './components/AuthSuccess.vue'
import firebase from './helpers/firebaseInit.js'
import { CREATOR } from './store/actions.type'
import store from './store'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	// eslint-disable-next-line no-unused-vars
	scrollBehavior: function(to, from, savedPosition) {
		if (to.hash) {
			return { selector: to.hash }
		} else {
			return { x: 0, y: 0 }
		}
	},
	routes: [
		{
			path: '/',
			component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Home.vue'),
			children: [
				{
					path: '',
					name: 'home'
				},
				{ name: 'about', path: '/about', component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue') },

				{ name: 'onboarding', path: '/onboarding', component: () => import(/* webpackChunkName: "about" */ '@/views/onboarding.vue') },
				{ name: 'login', path: '/login', component: () => import('@/views/Login') },
				{ name: 'register', path: '/register', component: () => import('@/views/Register') },

				{
					path: 'account',
					component: () => import('@/views/Accounts'),
					meta: {
						requireAuth: true
					},
					children: [
						{
							name: 'account',
							path: '',
							component: () => import('@/views/account/switch')
						},
						{
							name: 'account.create',
							path: 'create',
							component: () => import('@/views/account/create')
						},
						{
							name: 'account.add',
							path: 'add',
							component: () => import('@/views/account/add')
						}
					]
				}
			]
		},
		{ name: 'finish-sign-up', path: '/finishsignup', component: () => import('@/views/finishSignUp') },
		{
			name: 'admin',
			path: '/admin',
			component: () => import(/* webpackChunkName: "admin" */ '@/views/admin'),
			children: [
				{
					path: 'orphan',
					name: 'orphan',
					component: () => import(/* webpackChunkName: "admin" */ '@/components/admin/orphan')
				}
			]
		},
		{
			path: '/auth',
			name: 'auth',
			component: Auth
		},
		{
			path: '/success',
			name: 'success',
			component: AuthSuccess
		},
		{
			path: '/:account',
			component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
			meta: {
				requireAuth: true,
				requireSubscription: true,
				nav: [
					{
						id: 0,
						text: 'Models',
						target: 'models',
						icon: 'project-diagram'
					},
					{
						id: 1,
						text: 'Creators',
						target: 'creators',
						icon: 'pencil-ruler'
					},
					{
						id: 2,
						text: 'Entities',
						target: 'entities',
						icon: 'cube'
					},
					{
						id: 3,
						text: 'Fields',
						target: 'fields',
						icon: 'edit'
					},
					{
						id: 4,
						text: 'Channels',
						target: 'channels',
						icon: 'dot-circle'
					}
				]
			},
			children: [
				{
					path: '',
					name: 'dashboard',
					component: () => import(/* webpackChunkName: "models" */ '@/views/DashboardOverview.vue'),
					meta: {
						name: 'Dashboard'
					}
				},
				{
					path: 'account',
					component: () => import('@/views/account/settings'),
					meta: {
						isSettings: true
					},
					children: [
						{
							path: '',
							component: () => import('@/components/settings/accountOverview'),
							children: [
								{
									name: 'account-settings',
									path: '',
									component: () => import('@/components/settings/account'),
									meta: {
										name: 'Account Settings'
									}
								},
								{
									name: 'billing-settings',
									path: 'billing',
									component: () => import('@/components/settings/billing'),
									meta: {
										name: 'Billing'
									}
								},
								{
									name: 'license-settings',
									path: 'licenses',
									component: () => import('@/components/settings/licenses'),
									meta: {
										name: 'Licenses'
									}
								}
							]
						},
						{
							name: 'profile-settings',
							path: 'me',
							component: () => import('@/components/settings/profile')
						}
					]
				},
				{
					path: 'models',
					component: () => import(/* webpackChunkName: "models" */ '@/views/Models.vue'),
					meta: {
						name: 'Models'
					},
					children: [
						{
							path: '',
							name: 'models',
							component: () => import(/* webpackChunkName: "models" */ '@/components/model/Manage.vue'),
							meta: {
								name: 'Models'
							}
						},
						{
							path: 'create',
							props: true,
							name: 'create-model',
							component: () => import(/* webpackChunkName: "models" */ '@/components/model/Create.vue'),
							meta: {
								name: 'Create Model'
							}
						},
						{
							path: 'model/:id/edit',
							props: true,
							name: 'edit-model',
							component: () => import(/* webpackChunkName: "model" */ '@/components/model/Create.vue'),
							meta: {
								name: 'Edit Model'
							}
						}
					]
				},
				{ path: 'model', redirect: { name: 'models' } },
				{
					path: 'model/:id/:name',
					props: true,
					meta: {
						requireAuth: true,
						bcLinkText: 'Model'
					},
					component: () => import(/* webpackChunkName: "models" */ '@/views/Model'),
					children: [
						{
							path: '',
							props: true,
							name: 'model',

							component: () => import(/* webpackChunkName: "entities" */ '@/components/model'),
							meta: {
								bcLinkText: 'Overview',
								name: 'Model Overview',
								nav: [
									{
										id: 0,
										text: 'Overview',
										target: 'model',
										icon: 'project-diagram'
									},
									{
										id: 1,
										text: 'Configuration',
										target: 'model-config',
										icon: 'pencil-ruler'
									}
								]
							}
						},
						{
							path: 'configure',
							props: true,
							name: 'model-config',
							component: () => import(/* webpackChunkName: "entities" */ '@/components/model/Config'),
							meta: { name: 'Configure Model' }
						},
						{
							path: 'configure/entity/:configId/:configName',
							props: true,
							name: 'configure-entity',
							meta: {
								collectionName: 'entities',
								singleName: 'entity',
								assignAction: ENTITY.ADD,
								unassignAction: ENTITY.REMOVE,
								name: 'Edit Entity'
							},
							component: () => import(/* webpackChunkName: "entities" */ '@/components/entity/Configure.vue')
						},
						{
							path: 'configure/creator/:configId/:configName',
							props: true,
							name: 'configure-creator',
							meta: {
								collectionName: 'creators',
								singleName: 'creator',
								assignAction: CREATOR.ADD,
								unassignAction: CREATOR.REMOVE,
								name: 'Edit Creator'
							},
							component: () => import(/* webpackChunkName: "creators" */ '@/components/creators/Configure.vue')
						},
						{
							path: 'configure/channel/:configId/:configName',
							props: true,
							name: 'configure-channel',
							meta: {
								collectionName: 'channels',
								singleName: 'channel',
								assignAction: CHANNEL.ADD,
								unassignAction: CHANNEL.REMOVE,
								name: 'Edit Channel'
							},
							component: () => import(/* webpackChunkName: "channels" */ '@/components/channels/Configure.vue')
						},
						{
							path: 'configure/fields',
							props: true,
							name: 'configure-fields',
							meta: {
								collectionName: 'fields',
								singleName: 'field',
								assignAction: FIELD.ADD,
								unassignAction: FIELD.REMOVE,
								name: 'Edit Field'
							},
							component: () => import(/* webpackChunkName: "fields" */ '@/components/fields/Configure.vue')
						}
					]
				},
				{
					path: 'creators',
					props: true,
					component: () => import(/* webpackChunkName: "creators" */ '@/views/Creators.vue'),

					children: [
						{
							path: '',
							name: 'creators',
							meta: {
								name: 'Creators'
							},
							component: () => import(/* webpackChunkName: "creators" */ '@/components/creators/Manage.vue')
						},
						{
							path: 'create',
							props: { default: true, mode: 'create' },
							meta: {
								name: 'Create Creator'
							},
							name: 'create-creator',
							component: () => import(/* webpackChunkName: "creators" */ '@/components/creators/Create.vue')
						},
						{
							path: ':id',
							props: true,
							name: 'creator',
							component: () => import(/* webpackChunkName: "creators" */ '@/components/creators'),
							children: [
								{
									path: 'edit',
									props: true,
									name: 'edit-creator',
									component: () => import(/* webpackChunkName: "creators" */ '@/components/creators/Create.vue'),
									meta: {
										collectionName: 'creators',
										singleName: 'creator',
										name: 'Edit Creator',
										unassignAction: CREATOR.REMOVE
									}
								}
							]
						}
					]
				},
				{
					path: 'entities',
					component: () => import(/* webpackChunkName: "entities" */ '@/views/Entities.vue'),
					children: [
						{
							path: '',
							name: 'entities',
							meta: {
								name: 'Entities'
							},
							component: () => import(/* webpackChunkName: "entities" */ '@/components/entity/Manage.vue')
						},
						{
							path: 'create',
							props: true,
							name: 'create-entity',
							meta: {
								name: 'Create Entity'
							},
							component: () => import(/* webpackChunkName: "entities" */ '@/components/entity/Create.vue')
						},

						{
							path: ':id',
							props: true,
							name: 'entity',
							component: () => import(/* webpackChunkName: "entities" */ '@/components/entity'),
							children: [
								{
									path: 'edit',
									props: true,
									name: 'edit-entity',
									component: () => import(/* webpackChunkName: "entities" */ '@/components/entity/Create.vue'),
									meta: {
										collectionName: 'entities',
										singleName: 'entity',
										unassignAction: ENTITY.REMOVE,
										name: 'Edit Entity'
									}
								}
							]
						}
					]
				},
				{
					path: 'channels',
					component: () => import(/* webpackChunkName: "channels" */ '@/views/Channels.vue'),
					children: [
						{
							path: '',
							name: 'channels',
							meta: {
								name: 'Channels'
							},
							component: () => import(/* webpackChunkName: "channels" */ '@/components/channels/Manage.vue')
						},
						{
							path: 'create',
							props: true,
							name: 'create-channel',
							meta: {
								name: 'Create Channel'
							},
							component: () => import(/* webpackChunkName: "channels" */ '@/components/channels/Create.vue')
						},
						{
							path: ':id',
							props: true,
							name: 'channel',
							component: () => import(/* webpackChunkName: "channels" */ '@/components/channels'),
							children: [
								{
									path: 'edit',
									props: true,
									name: 'edit-channel',
									component: () => import(/* webpackChunkName: "channels" */ '@/components/channels/Create.vue'),
									meta: {
										collectionName: 'channels',
										singleName: 'channel',
										unassignAction: CHANNEL.REMOVE,
										name: 'Edit Channel'
									}
								}
							]
						}
					]
				},
				{
					path: 'fields',
					component: () => import(/* webpackChunkName: "fields" */ '@/views/Fields.vue'),
					children: [
						{
							path: '',
							name: 'fields',
							meta: {
								name: 'Fields'
							},
							component: () => import(/* webpackChunkName: "fields" */ '@/components/fields/Manage.vue')
						},
						{
							path: 'create',
							props: true,
							name: 'create-field',
							meta: {
								name: 'Create Field'
							},
							component: () => import(/* webpackChunkName: "fields" */ '@/components/fields/Create.vue')
						},
						{
							path: ':id',
							props: true,
							name: 'field',
							component: () => import(/* webpackChunkName: "fields" */ '@/components/fields'),
							children: [
								{
									path: 'edit',
									props: { default: true, mode: 'edit' },
									name: 'edit-field',
									component: () => import(/* webpackChunkName: "fields" */ '@/components/fields/Create.vue'),
									meta: {
										collectionName: 'fields',
										singleName: 'field',
										unassignAction: FIELD.REMOVE,
										name: 'Edit Field'
									}
								}
							]
						}
					]
				},
				{
					path: 'upload',
					component: () => import(/* webpackChunkName: "upload" */ '@/components/settings/upload.vue')
				}
			]
		}
	]
})

// eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from, next) => {
	const currentUser = firebase.auth.currentUser
	const requireAuth = to.matched.some((record) => record.meta.requireAuth)
	const requireSubscription = to.matched.some((record) => record.meta.requireSubscription)
	const isSettings = to.matched.some((record) => record.meta.isSettings)

	const publicAccess = !requireAuth && !requireSubscription
	const authenticatedAccess = publicAccess || (currentUser && requireAuth)

	let userHasAccountSubscription = false

	if (currentUser) {
		await store.dispatch('auth/refreshUser', currentUser.uid)
	}

	if (to.params.account) {
		if (!store.state.accounts.current?.length) {
			await store.dispatch('accounts/get', to.params.account)
		}
		if (store.state.accounts.current && store.state.accounts.current.members) {
			userHasAccountSubscription =
				authenticatedAccess && Object.keys(store.state.accounts.current.members).some((m) => m === currentUser.uid)
		}
	}

	if (publicAccess) {
		next()
	} else {
		if (!currentUser) {
			next('/')
		} else {
			if (to.params.account) {
				const isAccountSubscribed = store.state.accounts.current && store.state.accounts.current.subscription ? true : false

				if (!userHasAccountSubscription) {
					next({ name: 'home' })
				} else if (userHasAccountSubscription && isAccountSubscribed) {
					next()
				} else if (isSettings) {
					next()
				} else {
					next({ name: 'billing-settings', params: { account: to.params.account } })
				}
			} else {
				next()
			}
		}
	}
})

export default router
