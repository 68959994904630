import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import models from './model.module'
import entities from './entities.module'
import fields from './fields.module'
import rules from './rules.module'
import creators from './creators.module'
import channels from './channels.module'
import accounts from './accounts.module'
import connections from './connections.module'
// import configurations from './configurations.module'
import user from './user.module'
import ui from './ui.module'
import s from './dataSyncPlugin'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		models,
		accounts,
		user,
		entities,
		fields,
		rules,
		creators,
		channels,
		connections,
		ui,
		s,
		// configurations,
		auth
	},

	strict: process.env.NODE_ENV !== 'production'
})
