/* eslint-disable no-unused-vars */
const s = (store) => {
	store.subscribeAction({
		before: (action, state) => {
			//
		},
		after: (action, state) => {
			//
		}
	})
}

export default s
