import { GET, GET_ALL, DELETE, ASSIGNMENT, UPSERT, UPSERT_BATCH } from './actions.type'
import {
	SET_CURRENT,
	RESET_STATE,
	SET,
	SET_CURRENT_BY_ID,
	UPDATE,
	REMOVE,
	INCREMENT_FIELD,
	DECREMENT_FIELD,
	UNSET_CURRENT
} from './mutations.type'
import Vue from 'vue'

export const incrementField = (commit, data) => {
	commit(INCREMENT_FIELD, {
		fieldCount: data.sourceSingleName + 'Count',
		field: data.sourceCollectionName,
		item: { name: data.name, id: data.id }
	})
}
export const decrementField = (commit, data) => {
	commit(DECREMENT_FIELD, {
		fieldCount: data.sourceSingleName + 'Count',
		field: data.sourceCollectionName,
		id: data.id
	})
}

var fn = function commitItem(doc, commit) {
	return new Promise((resolve) => {
		let data = doc.data()
		let item = {
			id: doc.id,
			...data
		}
		commit({
			type: SET,
			payload: item,
			options: { root: true }
		})
		resolve()
	})
}

export const sharedMutations = {
	[SET](state, item) {
		state.all.push(item.payload)
	},
	[SET_CURRENT](state, { item }) {
		state.current = item
	},
	[UNSET_CURRENT](state) {
		state.current = Object.empty
	},
	[REMOVE](state, { id }) {
		state.all = state.all.filter((item) => {
			return item.id !== id
		})
		if (id == state.current.id) {
			state.current = {}
		}
	},
	[UPDATE](state, { item }) {
		var foundIndex = state.all.findIndex((x) => x.id == item.id)
		state.all[foundIndex] = item
		if (item.id == state.current.id) {
			state.current = item
		}
	},
	[RESET_STATE](state) {
		state.all = []
		state.current = {}
	},
	[SET_CURRENT_BY_ID](state, { id }) {
		var foundIndex = state.all.findIndex((x) => x.id == id)
		state.current = state.all[foundIndex]
	},
	[INCREMENT_FIELD](state, { field, item, fieldCount }) {
		let itemCount = state.current[fieldCount] ? state.current[fieldCount] : 0
		itemCount++
		Vue.set(state.current, fieldCount, itemCount)

		let newItem = { [item.id]: item }
		let newData = { ...state.current[field], ...newItem }
		Vue.set(state.current, field, newData)
	},
	[DECREMENT_FIELD](state, { field, id, fieldCount }) {
		if (state.current) {
			let itemCount = state.current[fieldCount] ? state.current[fieldCount] : 1
			itemCount--
			Vue.set(state.current, fieldCount, itemCount)
			Vue.delete(state.current[field], id)
		}
	}
}

export const sharedActions = (service) => ({
	async [GET]({ commit }, itemId) {
		// commit('ui/loading', true, { root: true })
		return service.get(itemId).then((doc) => {
			if (!doc.exists) {
				commit(UNSET_CURRENT)
				// commit('ui/loading', false, { root: true })
				return
			}
			let data = doc.data()
			let item = {
				id: doc.id,
				...data
			}
			commit(SET_CURRENT, { item })
			return item
			// commit('ui/loading', false, { root: true })
		})
	},
	async [GET_ALL]({ commit }) {
		commit('ui/loading', true, { root: true })
		await commit(RESET_STATE, { root: true })
		return service
			.getAll()
			.then((snapshot) => {
				if (snapshot.empty) {
					commit('ui/loading', false, { root: true })
					return
				}
				var promises = []
				snapshot.forEach((doc) => promises.push(fn(doc, commit)))
				Promise.all(promises).then(() => {
					commit('ui/loading', false, { root: true })
				})
			})
			.catch((err) => {
				console.log(err)
			})
	},
	async [ASSIGNMENT.ADD]({ commit }, assignInfo) {
		return service.assign(assignInfo).then((doc) => {
			let item = { ...doc.data() }
			commit(UPDATE, {
				item
			})
		})
	},
	async [ASSIGNMENT.REMOVE]({ commit }, assignInfo) {
		return service.unassign(assignInfo).then((doc) => {
			let item = { ...doc.data() }
			commit(UPDATE, {
				item
			})
		})
	},
	async [DELETE]({ commit }, id) {
		return service.delete(id).then(() => {
			commit(REMOVE, {
				id
			})
		})
	},
	async [UPSERT]({ commit }, data) {
		return service.upsert(data).then((doc) => {
			let data = doc.data()
			let itemData = {
				id: doc.id,
				...data
			}
			commit(UPDATE, {
				item: itemData
			})
		})
	},
	async [UPSERT_BATCH]({ commit }, dataCollection) {
		return service.upsertBatch(dataCollection).then((results) => {
			for (const r of results) {
				let itemData = {
					id: r.id,
					...r
				}
				commit(UPDATE, {
					itemData
				})
			}
		})
	}
})

export const sharedState = {
	all: [],
	current: {}
}

const sorter = function(a, b) {
	if (a._name > b._name) {
		return 1
	}
	if (b._name > a._name) {
		return -1
	}
	return 0
}

export const sharedGetters = {
	sorted: (state) => {
		return [...state.all].sort(sorter)
	},
	noDescriptions: (state) => {
		return [...state.all].filter((item) => !item.description)
	},
	noDescriptionsCount: (state) => {
		return {
			count: [...state.all].filter((item) => !item.description).length,
			total: state.all.length
		}
	},
	notUsed: (state) => {
		return [...state.all].filter((item) => !item.assignedTo)
	},
	notUsedCount: (state) => {
		return {
			count: [...state.all].filter((item) => !item.assignedTo).length,
			total: state.all.length
		}
	}
}

export default { sharedActions, sharedState, sharedMutations, sharedGetters }
