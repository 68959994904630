<template lang="html">
	<div style="margin-top:200px">
		<div id="firebaseui-auth-container" />
		<button gradient="blue" rounded class="btn-block z-depth-1a" @click="doThing">Sign In</button>
	</div>
</template>
<script>
import firebase from '@firebase/app'
import * as firebaseui from 'firebaseui'

export default {
	name: 'Auth',
	mounted() {
		//var ui = new firebaseui.auth.AuthUI(this.$store.state.auth)
		this.doThing()
	},
	methods: {
		doThing() {
			var uiConfig = {
				signInFlow: 'popup',
				signInSuccessUrl: '/account',
				signInOptions: [
					// Leave the lines as is for the providers you want to offer your users.
					firebase.auth.GoogleAuthProvider.PROVIDER_ID,
					firebase.auth.FacebookAuthProvider.PROVIDER_ID,
					firebase.auth.TwitterAuthProvider.PROVIDER_ID,
					firebase.auth.GithubAuthProvider.PROVIDER_ID,
					firebase.auth.EmailAuthProvider.PROVIDER_ID,
					firebase.auth.PhoneAuthProvider.PROVIDER_ID,
					firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
				]
			}
			var ui = new firebaseui.auth.AuthUI(firebase.auth())
			ui.start('#firebaseui-auth-container', uiConfig)
		}
	}
}
</script>
