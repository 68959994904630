import { ConnectionService as Service } from './common/api.service'
import { sharedActions, sharedState, sharedMutations } from './shared.module'
import { CONNECTION } from '@/store/actions.type'
import { SET, REMOVE } from './mutations.type'

let connectionActions = sharedActions(Service)

const initialState = {
	...sharedState
}

export const state = { ...initialState }

const getters = {
	connection: (state) => state.current,
	connections: (state) => state.all,
	target: (state) => (id) => state.all.filter((c) => c.target === id),
	source: (state) => (id) => state.all.filter((c) => c.source === id)
}

const mutations = {
	...sharedMutations
}

const actions = {
	...connectionActions,
	async [CONNECTION.ADD]({ commit }, connectionData) {
		return Service.upsert(connectionData).then((doc) => {
			let data = doc.data()
			let itemData = {
				id: doc.id,
				...data
			}
			commit({
				type: SET,
				payload: itemData
			})
		})
	},
	async [CONNECTION.ADD_BATCH]({ commit }, connectionData) {
		return Service.upsertBatch(connectionData).then((results) => {
			for (const r of results) {
				let itemData = {
					id: r.id,
					...r
				}
				commit({
					type: SET,
					payload: itemData
				})
			}
		})
	},
	async [CONNECTION.REMOVE_BATCH]({ commit }, ids) {
		return Service.deleteBatch(ids).then(() => {
			ids.forEach((id) => {
				commit(REMOVE, {
					id
				})
			})
		})
	},
	async [CONNECTION.REMOVE]({ commit }, id) {
		return Service.delete(id).then(() => {
			commit(REMOVE, {
				id
			})
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
