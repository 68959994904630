export const RESET_STATE = 'resetModuleState'
export const SET_CURRENT = 'setCurrent'
export const UNSET_CURRENT = 'unsetCurrent'
export const INCREMENT_FIELD = 'incrementField'
export const DECREMENT_FIELD = 'decrementField'
export const SET_CURRENT_BY_ID = 'setCurrentById'
export const SET = 'set'
export const UPDATE = 'update'
export const REMOVE = 'remove'
export const DECREMENT_CONFIGURATION_FIELD = 'decrementConfigurationField'
export const INCREMENT_CONFIGURATION_FIELD = 'incrementConfigurationField'
export const ADD_ENTITY_CONFIG = 'addEntityToConfig'
export const REMOVE_ENTITY_CONFIG = 'removeEntityFromConfig'
export const ENTITY = { SET: 'setEntity', REMOVE: 'removeEntity', UPDATE: 'updateEntity' }
export const CONNECTION = { SET: 'setConnection', REMOVE: 'removeConnection', UPDATE: 'updateConnection' }
export const MODEL = { SET: 'setModel', REMOVE: 'removeModel', UPDATE: 'updateModel' }
export const CHANNEL = { SET: 'setChannel', REMOVE: 'removeChannel', UPDATE: 'updateChannel' }
export const CREATOR = { SET: 'setCreator', REMOVE: 'removeCreator', UPDATE: 'updateCreator' }
export const FIELD = { SET: 'setField', REMOVE: 'removeField', UPDATE: 'updateField' }
export const RULE = { SET: 'setRule', REMOVE: 'removeRule', UPDATE: 'updateRule' }
export const ACCOUNT = { SET: 'setAccount', REMOVE: 'removeAccount', UPDATE: 'updateAccount' }
export const USER = { SET: 'setUser', REMOVE: 'removeUser', UPDATE: 'updateUser' }
