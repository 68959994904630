import fs from '@/helpers/firebaseInit'
import store from '@/store'
import helpers from '@/helpers/utils'

const ApiService = {
	async get(collection, id) {
		return collection.doc(id).get()
	},
	async getAll(collection) {
		return collection.where('account', '==', store.state.accounts.current.id).get()
	},
	async getAllByModel(collection, modelId) {
		return collection.where('modelId', '==', modelId).get()
	},
	async getAllByUser(collection, userId) {
		return collection.where('userId', '==', userId).get()
	},
	async upsert(collection, data) {
		if (store.state.accounts.current && store.state.accounts.current.id) {
			data.account = store.state.accounts.current.id
		}

		data._name = helpers.shortName(data.name)
		let itemRef

		if (data.id) {
			itemRef = collection.doc(data.id)
			data.updated = new Date()
			data.updatedBy = fs.auth.currentUser.uid
		} else {
			itemRef = collection.doc()
			data.created = new Date()
			data.createdBy = fs.auth.currentUser.uid
		}
		return await itemRef.set(data).then(async () => {
			return await itemRef.get()
		})
	},
	async upsertBatch(collection, dataCollection) {
		let batch = fs.firestore.batch()
		let rData = []
		for (const data of dataCollection) {
			if (store.state.accounts.current && store.state.accounts.current.id) {
				data.account = store.state.accounts.current.id
			}
			data._name = helpers.shortName(data.name)
			let itemRef

			if (data.id) {
				itemRef = collection.doc(data.id)
				data.updated = new Date()
				data.updatedBy = fs.auth.currentUser.uid
			} else {
				itemRef = collection.doc()
				data.created = new Date()
				data.createdBy = fs.auth.currentUser.uid
			}
			// Get a new write batch
			batch.set(itemRef, data)
			data.id = itemRef.id
			rData.push(data)
		}
		return await batch.commit().then(() => {
			return rData
		})
	},
	delete(collection, id) {
		return collection.doc(id).delete()
	},
	deleteBatch(collection, ids) {
		let batch = fs.firestore.batch()
		ids.forEach((id) => {
			let idRef = collection.doc(id)
			batch.delete(idRef)
		})
		return batch.commit()
	},
	async assign(collection, assignInfo) {
		let id = assignInfo.id
		let targetId = assignInfo.targetId
		let name = assignInfo.targetName
		await this.get(collection, id).then((doc) => {
			let prop = doc.get('assignedTo') ? doc.get('assignedTo') : {}
			prop[targetId] = {
				id: targetId,
				name
			}
			let data = {
				...doc.data(),
				updated: new Date(),
				updatedBy: fs.auth.currentUser.uid,
				assignedTo: prop
			}
			collection.doc(id).set(data)
		})
		return this.get(collection, id)
	},
	async unassign(collection, assignInfo) {
		let id = assignInfo.id
		let targetId = assignInfo.targetId
		let docRef = collection.doc(id)

		await this.get(collection, id).then((doc) => {
			let prop = doc.get('assignedTo') ? doc.get('assignedTo') : {}
			delete prop[targetId]

			docRef.set({ ...doc.data(), assignedTo: prop })
		})
		return this.get(collection, id)
	},
	async addConfigurationField(collection, data, fieldCollection) {
		let id = data.configurationId
		let dataId = data.id
		let entityId = data.entityId
		let entityName = data.entityName

		let sourceItem = {}
		await this.get(fieldCollection, dataId).then((snap) => {
			let d = snap.data()
			sourceItem = {
				name: d.name,
				id: dataId
			}
		})

		let docRef = collection.doc(id)

		return docRef.get().then((doc) => {
			let entities = doc.get('entities') ? doc.get('entities') : {}
			let entity = entities[entityId] ? entities[entityId] : {}
			let fields = entity['fields'] ? entity['fields'] : {}

			fields[dataId] = { ...sourceItem }
			entity.name = entityName
			entity.id = entityId
			entity.fields = { ...fields }

			let data = {
				...doc.data(),
				updated: new Date(),
				updatedBy: fs.auth.currentUser.uid,
				entities: { ...entities, [entityId]: { ...entity } }
			}

			collection.doc(id).set(data)
		})
	},
	async addConfigurationEntity(collection, data) {
		let id = data.configurationId
		let entityId = data.id
		let entityName = data.name

		let docRef = collection.doc(id)

		return docRef.get().then((doc) => {
			let entities = doc.get('entities') ? doc.get('entities') : {}
			let entity = entities[entityId] ? entities[entityId] : {}

			entity.name = entityName
			entity.id = entityId

			let data = {
				...doc.data(),
				updated: new Date(),
				updatedBy: fs.auth.currentUser.uid,
				entities: { ...entities, [entityId]: { ...entity } }
			}

			collection.doc(id).set(data)
		})
	},
	async removeConfigurationEntity(collection, data) {
		let id = data.configurationId
		let targetId = data.id
		let docRef = collection.doc(id)

		await this.get(collection, id).then((doc) => {
			let prop = doc.get('entities') ? doc.get('entities') : {}
			delete prop[targetId]
			docRef.set({ ...doc.data(), entities: prop })
		})
		return this.get(collection, id)
	},
	async removeConfigurationField(collection, data) {
		let id = data.configurationId
		let dataId = data.id
		let entityId = data.entityId

		let docRef = collection.doc(id)

		return docRef.get().then((doc) => {
			let entities = doc.get('entities') ? doc.get('entities') : {}
			let entity = entities[entityId] ? entities[entityId] : {}
			let fields = entity['fields'] ? entity['fields'] : {}

			delete fields[dataId]
			entity.fields = { ...fields }

			let data = {
				...doc.data(),
				updated: new Date(),
				updatedBy: fs.auth.currentUser.uid,
				entities: { ...entities, [entityId]: { ...entity } }
			}

			collection.doc(id).set(data)
		})
	},
	async addModelEntity(collection, data, entityCollection) {
		let id = data.targetId
		let dataId = data.id
		let name = data.sourceCollectionName
		let countName = data.sourceSingleName + 'Count'

		let entity = {}

		await this.get(entityCollection, dataId).then((snap) => {
			let d = snap.data()
			entity = {
				name: d.name,
				id: dataId
			}
		})

		let docRef = collection.doc(id)

		return docRef.get().then((doc) => {
			let prop = doc.get(name) ? doc.get(name) : {}
			prop[dataId] = {
				...entity
			}
			let itemCount = doc.get(countName) ? doc.get(countName) : 0
			itemCount++
			docRef.set({ [name]: prop, [countName]: itemCount }, { merge: true })
		})
	},
	async addModelChannel(collection, data, channelCollection) {
		let id = data.targetId
		let dataId = data.id
		let name = data.sourceCollectionName
		let countName = data.sourceSingleName + 'Count'

		let channel = {}

		await this.get(channelCollection, dataId).then((snap) => {
			let d = snap.data()
			channel = {
				name: d.name,
				id: dataId
			}
		})

		let docRef = collection.doc(id)

		return docRef.get().then((doc) => {
			let prop = doc.get(name) ? doc.get(name) : {}
			prop[dataId] = {
				...channel
			}
			let itemCount = doc.get(countName) ? doc.get(countName) : 0
			itemCount++
			docRef.set({ [name]: prop, [countName]: itemCount }, { merge: true })
		})
	},
	async addModelItem(collection, data, sourceCollection) {
		let id = data.targetId
		let dataId = data.id
		let name = data.sourceCollectionName
		let countName = data.sourceSingleName + 'Count'

		let childItem = {}

		await this.get(sourceCollection, dataId).then((snap) => {
			let d = snap.data()
			childItem = {
				name: d.name,
				id: dataId
			}
		})

		let docRef = collection.doc(id)

		return docRef.get().then((doc) => {
			let prop = doc.get(name) ? doc.get(name) : {}
			prop[dataId] = {
				...childItem
			}
			let itemCount = doc.get(countName) ? doc.get(countName) : 0
			itemCount++
			docRef.set({ [name]: prop, [countName]: itemCount }, { merge: true })
		})
	},
	async addModelCreator(collection, data, sourceCollection) {
		let id = data.targetId
		let dataId = data.id
		let name = data.sourceCollectionName
		let countName = data.sourceSingleName + 'Count'

		let childItem = {}

		await this.get(sourceCollection, dataId).then((snap) => {
			let d = snap.data()
			childItem = {
				name: d.name,
				id: dataId
			}
		})

		let docRef = collection.doc(id)

		return docRef.get().then((doc) => {
			let prop = doc.get(name) ? doc.get(name) : {}
			prop[dataId] = {
				...childItem
			}
			let itemCount = doc.get(countName) ? doc.get(countName) : 0
			itemCount++
			docRef.set({ [name]: prop, [countName]: itemCount }, { merge: true })
		})
	},
	async removeModelPropertyAndCount(collection, data) {
		let id = data.targetId
		let dataId = data.id
		let name = data.sourceCollectionName
		let countName = data.sourceSingleName + 'Count'

		let docRef = collection.doc(id)
		return docRef.get().then((doc) => {
			let prop = doc.get(name) ? doc.get(name) : {}
			delete prop[dataId]

			let itemCount = doc.get(countName) ? doc.get(countName) : 0
			itemCount--
			docRef.update({ [name]: prop, [countName]: itemCount })
		})
	},
	async addModelField(collection, data, sourceCollection) {
		let id = data.targetId
		let dataId = data.id
		let name = data.sourceCollectionName
		let countName = data.sourceSingleName + 'Count'

		let childItem = {}

		await this.get(sourceCollection, dataId).then((snap) => {
			let d = snap.data()
			childItem = {
				name: d.name,
				id: dataId
			}
		})

		let docRef = collection.doc(id)

		return docRef.get().then((doc) => {
			let prop = doc.get(name) ? doc.get(name) : {}
			prop[dataId] = {
				...childItem
			}
			let itemCount = doc.get(countName) ? doc.get(countName) : 0
			itemCount++
			docRef.set({ [name]: prop, [countName]: itemCount }, { merge: true })
		})
	},
	async addModelRule(collection, data, sourceCollection) {
		let id = data.targetId
		let dataId = data.id
		let name = data.sourceCollectionName
		let countName = data.sourceSingleName + 'Count'

		let childItem = {}

		await this.get(sourceCollection, dataId).then((snap) => {
			let d = snap.data()
			childItem = {
				name: d.name,
				id: dataId
			}
		})

		let docRef = collection.doc(id)

		return docRef.get().then((doc) => {
			let prop = doc.get(name) ? doc.get(name) : {}
			prop[dataId] = {
				...childItem
			}
			let itemCount = doc.get(countName) ? doc.get(countName) : 0
			itemCount++
			docRef.set({ [name]: prop, [countName]: itemCount }, { merge: true })
		})
	},
	addFieldAndCountToModel(collection, data) {
		let id = data.parentId
		let dataId = data.itemId
		let name = data.fieldName
		let countName = data.fieldCountName

		let docRef = collection.doc(id)
		return docRef.get().then((doc) => {
			let prop = doc.get(name) ? doc.get(name) : {}
			prop[dataId] = true
			let itemCount = doc.get(countName) ? doc.get(countName) : 0
			itemCount++
			docRef.set({ [name]: prop, [countName]: itemCount }, { merge: true })
		})
	},
	removeFieldAndCountFromModel(collection, data) {
		let id = data.parentId
		let dataId = data.itemId
		let name = data.fieldName
		let countName = data.fieldCountName

		let docRef = collection.doc(id)
		return docRef.get().then((doc) => {
			const obj = doc.get(name)
			delete obj[dataId]
			let itemCount = doc.get(countName) ? doc.get(countName) : 0
			if (itemCount != 0) {
				itemCount--
			}
			docRef.update({ [name]: obj, [countName]: itemCount })
		})
	},
	addExistingAccount(collection, accountInfo) {
		let account = collection
			.where('_name', '==', accountInfo.accountName)
			.where('key', '==', accountInfo.accountKey)
			.limit(1)

		return account.get().then(async (querySnapshot) => {
			if (!querySnapshot.empty) {
				let doc = querySnapshot.docs[0]

				let exists = await fs.membershipCollection
					.where('account', '==', doc.id)
					.where('userId', '==', fs.auth.currentUser.uid)
					.get()
					.then((q) => {
						if (!q.empty) {
							return doc
						}
					})
				if (exists) {
					//
					return exists
				}

				let membership = {
					created: new Date(),
					account: doc.id,
					accountName: doc.data().name,
					userId: fs.auth.currentUser.uid,
					email: fs.auth.currentUser.email
				}

				let itemRef = fs.membershipCollection.doc()
				return itemRef
					.set(membership)
					.then(async () => {
						membership.membershipId = itemRef.id
						var addDetails = fs.functions.httpsCallable('account-addMembership')

						await addDetails(membership)
						return doc
					})
					.catch((e) => {
						return e
					})
			} else {
				return null
			}
		})
	}
}

export const ModelService = {
	async getAll() {
		return ApiService.getAll(fs.modelCollection)
	},
	get(id) {
		return ApiService.get(fs.modelCollection, id)
	},
	upsert(model) {
		return ApiService.upsert(fs.modelCollection, model)
	},
	delete(id) {
		return ApiService.delete(fs.modelCollection, id)
	},
	addEntity(data) {
		return ApiService.addModelItem(fs.modelCollection, data, fs.entityCollection)
	},
	addChannel(data) {
		return ApiService.addModelItem(fs.modelCollection, data, fs.channelCollection)
	},
	addCreator(data) {
		return ApiService.addModelItem(fs.modelCollection, data, fs.creatorCollection)
	},
	addField(data) {
		return ApiService.addModelItem(fs.modelCollection, data, fs.fieldCollection)
	},
	addRule(data) {
		return ApiService.addModelItem(fs.modelCollection, data, fs.ruleCollection)
	},
	removeEntity(data) {
		return ApiService.removeModelPropertyAndCount(fs.modelCollection, data)
	},
	removeChannel(data) {
		return ApiService.removeModelPropertyAndCount(fs.modelCollection, data)
	},
	removeRule(data) {
		return ApiService.removeModelPropertyAndCount(fs.modelCollection, data)
	},
	removeField(data) {
		return ApiService.removeModelPropertyAndCount(fs.modelCollection, data)
	},
	removeCreator(data) {
		return ApiService.removeModelPropertyAndCount(fs.modelCollection, data)
	},
	addFieldAndCount(data) {
		return ApiService.addFieldAndCountToModel(fs.modelCollection, data)
	},
	removeFieldAndCount(data) {
		return ApiService.removeFieldAndCountFromModel(fs.modelCollection, data)
	}
}

export const ChannelService = {
	getAll() {
		return ApiService.getAll(fs.channelCollection)
	},
	getAllByModel(modelId) {
		return ApiService.getAllByModel(fs.channelCollection, modelId)
	},
	get(id) {
		return ApiService.get(fs.channelCollection, id)
	},
	assign(assignInfo) {
		return ApiService.assign(fs.channelCollection, assignInfo)
	},
	unassign(assignInfo) {
		return ApiService.unassign(fs.channelCollection, assignInfo)
	},
	upsert(channel) {
		return ApiService.upsert(fs.channelCollection, channel)
	},
	delete(id) {
		return ApiService.delete(fs.channelCollection, id)
	},
	addFieldAndCount(data) {
		return ApiService.addFieldAndCountToModel(fs.channelCollection, data)
	},
	removeFieldAndCount(data) {
		return ApiService.removeFieldAndCountFromModel(fs.channelCollection, data)
	}
}
export const CreatorService = {
	getAll() {
		return ApiService.getAll(fs.creatorCollection)
	},
	getAllByModel(modelId) {
		return ApiService.getAllByModel(fs.creatorCollection, modelId)
	},
	get(id) {
		return ApiService.get(fs.creatorCollection, id)
	},
	upsert(creator) {
		return ApiService.upsert(fs.creatorCollection, creator)
	},
	assign(assignInfo) {
		return ApiService.assign(fs.creatorCollection, assignInfo)
	},
	unassign(assignInfo) {
		return ApiService.unassign(fs.creatorCollection, assignInfo)
	},
	delete(id) {
		return ApiService.delete(fs.creatorCollection, id)
	},
	addFieldAndCount(data) {
		return ApiService.addFieldAndCountToModel(fs.creatorCollection, data)
	},
	removeFieldAndCount(data) {
		return ApiService.removeFieldAndCountFromModel(fs.creatorCollection, data)
	}
}

export const EntityService = {
	getAll() {
		return ApiService.getAll(fs.entityCollection)
	},
	getAllByModel(modelId) {
		return ApiService.getAllByModel(fs.entityCollection, modelId)
	},
	get(id) {
		return ApiService.get(fs.entityCollection, id)
	},
	upsert(entity) {
		return ApiService.upsert(fs.entityCollection, entity)
	},
	assign(assignInfo) {
		return ApiService.assign(fs.entityCollection, assignInfo)
	},
	unassign(assignInfo) {
		return ApiService.unassign(fs.entityCollection, assignInfo)
	},
	delete(id) {
		return ApiService.delete(fs.entityCollection, id)
	},
	addField(data) {
		return ApiService.addFieldToEntity(fs.entityCollection, data, fs.fieldCollection)
	},
	addFieldAndCount(data) {
		return ApiService.addFieldAndCountToModel(fs.entityCollection, data)
	},
	removeFieldAndCount(data) {
		return ApiService.removeFieldAndCountFromModel(fs.entityCollection, data)
	}
}

export const FieldService = {
	getAll() {
		return ApiService.getAll(fs.fieldCollection)
	},
	getAllByModel(modelId) {
		return ApiService.getAllByModel(fs.fieldCollection, modelId)
	},
	get(id) {
		return ApiService.get(fs.fieldCollection, id)
	},
	upsert(field) {
		return ApiService.upsert(fs.fieldCollection, field)
	},
	assign(assignInfo) {
		return ApiService.assign(fs.fieldCollection, assignInfo)
	},
	unassign(assignInfo) {
		return ApiService.unassign(fs.fieldCollection, assignInfo)
	},
	delete(id) {
		return ApiService.delete(fs.fieldCollection, id)
	},
	addFieldAndCount(data) {
		return ApiService.addFieldAndCountToModel(fs.fieldCollection, data)
	},
	removeFieldAndCount(data) {
		return ApiService.removeFieldAndCountFromModel(fs.fieldCollection, data)
	}
}
export const RuleService = {
	getAll() {
		return ApiService.getAll(fs.ruleCollection)
	},
	getAllByModel(modelId) {
		return ApiService.getAllByModel(fs.ruleCollection, modelId)
	},
	get(id) {
		return ApiService.get(fs.ruleCollection, id)
	},
	upsert(rule) {
		return ApiService.upsert(fs.ruleCollection, rule)
	},
	delete(id) {
		return ApiService.delete(fs.ruleCollection, id)
	},
	assign(assignInfo) {
		return ApiService.assign(fs.ruleCollection, assignInfo)
	},
	unassign(assignInfo) {
		return ApiService.unassign(fs.ruleCollection, assignInfo)
	},
	addFieldAndCount(data) {
		return ApiService.addFieldAndCountToModel(fs.ruleCollection, data)
	},
	removeFieldAndCount(data) {
		return ApiService.removeFieldAndCountFromModel(fs.ruleCollection, data)
	}
}

export const ConfigurationService = {
	getAll() {
		return ApiService.getAll(fs.configurationCollection)
	},
	getAllByModel(modelId) {
		return ApiService.getAllByModel(fs.configurationCollection, modelId)
	},
	get(id) {
		return ApiService.get(fs.configurationCollection, id)
	},
	upsert(configuration) {
		return ApiService.upsert(fs.configurationCollection, configuration)
	},
	addField(data) {
		return ApiService.addConfigurationField(fs.configurationCollection, data, fs.fieldCollection)
	},
	removeField(data) {
		return ApiService.removeConfigurationField(fs.configurationCollection, data)
	},
	addEntity(data) {
		return ApiService.addConfigurationEntity(fs.configurationCollection, data)
	},
	removeEntity(data) {
		return ApiService.removeConfigurationEntity(fs.configurationCollection, data)
	},
	delete(id) {
		return ApiService.delete(fs.configurationCollection, id)
	},
	addFieldAndCount(data) {
		return ApiService.addFieldAndCountToModel(fs.configurationCollection, data)
	},
	removeFieldAndCount(data) {
		return ApiService.removeFieldAndCountFromModel(fs.configurationCollection, data)
	}
}

export const ConnectionService = {
	getAll() {
		return ApiService.getAll(fs.connectionCollection)
	},
	upsert(connection) {
		connection.createdBy = fs.auth.currentUser.uid
		return ApiService.upsert(fs.connectionCollection, connection)
	},
	upsertBatch(connections) {
		return ApiService.upsertBatch(fs.connectionCollection, connections)
	},
	deleteBatch(ids) {
		return ApiService.deleteBatch(fs.connectionCollection, ids)
	},
	delete(id) {
		return ApiService.delete(fs.connectionCollection, id)
	}
}

export const AccountService = {
	getAll() {
		return ApiService.getAll(fs.accountCollection)
	},
	getAllByUser(userId) {
		return ApiService.getAllByUser(fs.accountCollection, userId)
	},
	get(id) {
		return ApiService.get(fs.accountCollection, id)
	},
	upsert(account) {
		account.createdBy = fs.auth.currentUser.uid
		account.owner = fs.auth.currentUser.uid
		account.email = fs.auth.currentUser.email
		account.id = account._name
		account.key = helpers.getRandomId()
		return ApiService.upsert(fs.accountCollection, account)
	},
	addExisting(accountInfo) {
		return ApiService.addExistingAccount(fs.accountCollection, accountInfo)
	},
	delete(id) {
		return ApiService.delete(fs.accountCollection, id)
	},
	addFieldAndCount(data) {
		return ApiService.addFieldAndCountToModel(fs.accountCollection, data)
	},
	removeFieldAndCount(data) {
		return ApiService.removeFieldAndCountFromModel(fs.accountCollection, data)
	}
}

export const UserService = {
	get(id) {
		return ApiService.get(fs.userCollection, id)
	},
	upsert(user) {
		return ApiService.upsert(fs.userCollection, user)
	},
	delete(id) {
		return ApiService.delete(fs.userCollection, id)
	},
	addFieldAndCount(data) {
		return ApiService.addFieldAndCountToModel(fs.userCollection, data)
	},
	removeFieldAndCount(data) {
		return ApiService.removeFieldAndCountFromModel(fs.userCollection, data)
	}
}
